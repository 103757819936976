<template>
  <div class="container py-3 py-md-4 py-lg-5">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="fs-4">{{ title }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" v-for="(item, idx) in content" :key="`item${idx}`">
            <a @click.prevent="$router.push(item.path)" href="#">{{ item.name }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    content: {
      type: Array,
    },
  },
};
</script>

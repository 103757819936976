<template>
  <div class="container">
    <img :src="$imageFilters('/images/banner/Banner_home_1200.svg')" alt="關於仁大" />
    <PageTitle title="隱私條款" :content="breadcrumb" />
    <p class="mb-1">1、 認知與接受條款</p>
    <p class="mb-1">
      1.1 仁大資訊股份有限公司(High Performance Information Co.
      Ltd.)(以下簡稱「HPI」)爰依本服務條款提供Lifeeasy網站（http://www.lifeeasy.com.tw）服務
      (以下簡稱「本服務」)，本服務並包含Lifeeasy提供的特定服務。當您開始使用本服務及/或特定服務時，即表示您已閱讀、瞭解並同意接受本約定書之所有內容。HPI有權於任何時間修改或變更本約定書之內容，您可隨時到Lifeeasy網站瞭解相關修訂內容，並建議您隨時注意該等修改或變更。如果您不同意本約定書的內容，或者您所屬的國家或地域排除本約定書內容之全部或一部時，您應立即停止使用Lifeeasy網站。如您不同意會員服務條款的修訂內容。您應停止使用本服務，如您仍繼續使用本服務，則視為您已閱讀、瞭解並同意接受該等修改或變更。
    </p>
    <p class="mb-4">
      1.2
      若您未滿二十歲，除應符合上述規定外，並應於您的家長（或監護人）閱讀、瞭解並同意本約定書之所有內容及其後修訂內容後，方得使用或繼續使用本服務。當您使用或繼續使用本服務時，即表示您的家長（或監護人）已閱讀、瞭解並同意接受本約定書之所有內容及其後修改變更。為確保兒童及青少年使用網路之安全，並避免隱私權受到侵犯，家長（或監護人）在未滿十二歲之兒童上網時，應全程在旁陪伴，十二歲以上未滿十八歲之青少年上網前亦應斟酌是否給予同意。
    </p>
    <p class="mb-1">2、 您的註冊義務</p>
    <p class="mb-1">2.1 為了能使用本服務，您同意以下事項：</p>
    <p class="mb-1">2.1.1 依本服務註冊表之提示提供您本人正確、最新及完整的資料；</p>
    <p class="mb-4">
      2.1.2
      維持並更新您個人資料，確保其為正確、最新及完整。若您提供之資料有任何錯誤、不實或不完整，Lifeeasy網站有權暫停或終止您的帳號，並拒絕您使用本服務之全部或一部。
    </p>
    <p class="mb-1">3、 與第三人網站之連結</p>
    <p class="mb-4">
      3.1
      本服務可能會提供連結至其他網站或網路資源。您可能會因此連結至其他業者經營的網站，但不表示Lifeeasy與該等業者有任何關係。其他業者經營的網站均由各該業者自行負責，不屬Lifeeasy控制及負責範圍之內。Lifeeasy對任何檢索結果或外部連結，不擔保其合適性、可依賴性、即時性、有效性、正確性及完整性。您也許會檢索或連結到一些令您厭惡或不需要之網站，這是網際網路運作之可能結果，遇到此類情形時，Lifeeasy誠摯的建議您不要瀏覽或儘速離開該等網站。您已充分了解並同意Lifeeasy無須為您連結至非屬於Lifeeasy之網站所生之任何損害或損失，負損害賠償或損失補償之責任。
    </p>

    <p class="mb-1">4、 隱私權保護政策</p>
    <p class="mb-1">
      4.1 個人隱私權是您的重要權利。Lifeeasy
      網站非常重視您的隱私權，因此制訂了隱私權保護政策，關於您的會員註冊以及特定資料依Lifeeasy
      網站隱私權保護政策受到保護與規範。本隱私權保護政策僅適用於Lifeeasy網站，涵蓋的內容包括Lifeeasy網站如何處理蒐集或收到的個人資料。個人資料是指依法得以識別您的身分且未公開的資料，如姓名、地址、電子郵件地址或電話號碼。您了解當您使用本服務時，同意Lifeeasy
      網站依「隱私權保護政策」對您的個人資料予以蒐集與利用，包括跨國間的傳輸與儲存及Lifeeasy
      網站與其關係企業內部之利用。
    </p>
    <p class="mb-1">
      4.2 在您瀏覽 Lifeeasy網站網頁或某些合作夥伴的網頁、使用
      Lifeeasy網站產品或服務、或進一步對Lifeeasy網站或Lifeeasy網站產品或服務註冊帳號，以及參加宣傳活動或贈獎遊戲時，Lifeeasy網站會蒐集您的個人資料。Lifeeasy網站也可能從商業夥伴或其他公司處取得您的個人資料，並將這些資料與
      Lifeeasy網站所擁有的您的個人資料相結合。
    </p>
    <p class="mb-1">
      4.3 當您在註冊時，我們會要求您提供個人資料
      (例如使用者名稱、聯繫電話、年齡、電子郵件地址以及帳戶密碼)，該資料必須正確且完整；另外，因為Lifeeasy網站商品或服務可能會涉及消費行為等法律行為，如果您是未成年人，在此提醒您，請依法於註冊前取得法定代理人的允許。註冊成功後登入我們的服務，我們就能辨別您的身分。如果此資訊的使用目的有變更，我們會在使用前事先徵求您的同意。對於使用某些服務
      (例如使用Codo Go
      App購票)，我們還可能會要求您提供信用卡等付款帳戶資訊，在資料傳遞過程中會以加密形式保護資訊安全，而且不會將該資訊儲存在伺服器上。我們可能會將您透過帳戶提交的資訊與來自其他Lifeeasy網站服務或第三方的資訊合併，以便為您提供更好的使用體驗，並改善我們的服務品質，及作為規劃網頁服務時的參考。您可以拒絕向任何Lifeeasy網站產品或服務註冊，不過我們也可能因此無法為您提供某些服務。本隱私權保護政策所述及的個人資料，除非有個別指明或文意上另有其意外，原則上指的是您註冊成功的個人資料。
    </p>
    <p class="mb-1">
      4.4
      Lifeeasy網站也會在您造訪時自動接收並記錄您電腦和瀏覽器上的所傳送的資料，該資料可能包括IP位址、Lifeeasy網站cookie中的資料、軟體和硬體屬性以及您瀏覽的網頁紀錄、瀏覽器類型、語言、傳送請求的日期、時間。Lifeeasy網站一般將資料用於客製化廣告及您看到的網頁內容、滿足您對產品和服務的要求、改進我們的服務。
    </p>
    <p class="mb-1">
      4.5 對於Lifeeasy
      網站上搜尋出的或連結的其他合作夥伴、公司或店家首頁或自有的網站、其他合作夥伴、公司或店家提供的促銷活動、優惠券訊息、電子檔案、超鏈結等，我們並無控制權。這些來源可能會在您的電腦上放置自己的
      Cookie
      ，以收集資料或要求您提供個人資料，其他合作夥伴、公司或店家將根據其自訂的隱私權政策使用其cookie，並非依據本隱私權保護政策。而其他合作夥伴、公司或店家不能存取Lifeeasy
      網站的
      cookie。大部分瀏覽器都預設為接受Cookie，但您可以從電腦中之硬碟執行清除、阻斷接受Cookies、或於Cookies被儲存前接獲警告通知。您可以選擇自行設定瀏覽器在接獲Cookies前通知您，並得以選擇拒絕或接受該Cookies。不過，我們仍提醒您，如果您停用了Cookie，某些Lifeeasy網站的功能和服務可能無法正常運作。重要聲明：我們並沒有不當或惡意使用這類Cookie技術。Lifeeasy
      網站不會將您留下的個人資料分享給這些網站，而且對其他網站上所揭露之聲明、使用規則、策略或其他內容不負任何責任。我們建議您詳細瞭解這些網站上關於隱私權聲明之細項說明。
    </p>
    <p class="mb-1">
      4.6 Lifeeasy網站產品或服務（例如Code Go App）可能會提供定位服務。基於Google
      Map開放API為基礎，無論您使用何種形式的電腦處理器、手機或其他可連接網際網路之設備使用此定位服務，皆會被事先主動詢問您是否同意提供地理位置相關資訊。
    </p>
    <p class="mb-1">
      4.7 除非是為了向您提供您所要求的產品或服務、獲得您的授權，或有以下情形，否則
      Lifeeasy網站不會向任何其他人士或非關係企業出借或出售您的個人資料，或與之分享：
    </p>
    <p class="mb-1">4.7.1 應遵守法令或政府機關的要求；</p>
    <p class="mb-1">
      4.7.2 當您同意參予Lifeeasy 網站上非由Lifeeasy網站主辦之活動，即視為同意Lifeeasy
      網站提供您的聯絡資料予活動主辦者。
    </p>
    <p class="mb-1">
      4.7.3
      如果因依法合併，我們必須將您的個人資料移轉給該公司，Lifeeasy網站會在您的個人資料被移轉且將適用不同的有關隱私權政策前通知您。
    </p>
    <p class="mb-1">
      4.8
      當您使用Lifeeasy網站商品或服務時，您有權可隨時查閱自己的個人資料，並修正不正確的資訊。我們會採取適當的安全措施，來防止未經授權的資料存取、增減、變更或揭露，包括對於資料蒐集、處理及使用的內部審查及實體的安全措施，並確保資訊的正確性、完整性和即時性。
    </p>
    <p class="mb-1">
      4.9
      我們會授權負有保密義務的人員，可能是員工、代理人或合作廠商人員，存取您的個人資料，因為需要此類資訊用以提供、開發或改善我們的產品或服務。Lifeeasy網站處理及使用個人資料時，會嚴格遵守當初蒐集資訊的目的及本隱私權保護政策或其他隱私權保護的通知。
    </p>
    <p class="mb-1">
      4.10 Lifeeasy
      網站亦得蒐集任何未具名的線上瀏覽者相關使用資料，例如瀏覽者的興趣、習慣、性別傾向或年齡層等。此類資料將被使用於設計Lifeeasy網站，能夠更貼近或接近瀏覽者的需求與上網環境。Lifeeasy網站也可能將此類資料提供給合作夥伴、廣告商或贊助商等。
    </p>
    <p class="mb-4">
      4.11
      您要終止我們使用您的個人資料，請與Lifeeasy網站服務人員聯繫，在確認個別使用者身分證明後，我們將盡速從我們的資料庫中移除您留存的個人資料，移除後您將無法繼續享有Lifeeasy網站提供的商品與服務。
    </p>
    <p class="mb-1">5、 會員帳號、密碼及安全</p>
    <p class="mb-1">
      5.1
      完成本服務的登記程序之後，您將收到一個密碼及帳號。維持密碼及帳號的機密安全，是您的責任。利用該密碼及帳號所進行之一切行動，您將負完全的責任。您並同意以下事項：
    </p>
    <p class="mb-1">
      5.1.1 您的密碼或帳號遭到盜用或有其他任何安全問題發生時，您將立即通知Lifeeasy 網站；
    </p>
    <p class="mb-1">5.1.2 且每次連線完畢，均結束您的帳號使用。</p>
    <p class="mb-4">
      5.2
      您同意您的Lifeeasy帳號係不可轉讓，且您Lifeeasy帳號或帳號中內容之任何權利，於您死亡時即立刻終止。於Lifeeasy接獲您的死亡證明文件時，您的帳號將被終止，且其所有內容將被永久刪除。
    </p>
    <p class="mb-1">6、 您的守法義務及承諾</p>
    <p class="mb-1">
      6.1 您承諾絕不為任何非法目的或以任何非法方式使用Lifeeasy
      網站，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。您同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：
    </p>
    <p class="mb-1">
      6.1.1
      公佈或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於Lifeeasy
      網站上；
    </p>
    <p class="mb-1">
      6.1.2 侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利；
    </p>
    <p class="mb-1">6.1.3 違反依法律或契約所應負之保密義務；</p>
    <p class="mb-1">6.1.4 冒用他人名義使用本服務；</p>
    <p class="mb-1">6.1.5 傳輸或散佈電腦病毒；</p>
    <p class="mb-1">6.1.6 從事不法交易行為或張貼虛假不實、引人犯罪之訊息；</p>
    <p class="mb-1">6.1.7 販賣槍枝、毒品、禁藥、盜版軟體或其他違禁物；</p>
    <p class="mb-1">6.1.8 提供賭博資訊或以任何方式引誘他人參與賭博；</p>
    <p class="mb-1">6.1.9 濫發廣告郵件；</p>
    <p class="mb-1">6.1.10 破壞、干擾或入侵Lifeeasy 網站上各項資料、功能、系統之企圖或行為；</p>
    <p class="mb-4">6.1.11 其他Lifeeasy 網站有正當理由認為不適當之行為。</p>
    <p class="mb-1">7、 系統中斷或故障</p>
    <p class="mb-4">
      7.1
      本服務有時可能會出現中斷或故障等現象，或許將造成您使用上的不便、資料喪失、錯誤、遭人篡改或其他經濟上損失等情形。您於使用本服務時宜自行採取防護措施。HPI對於您因使用（或無法使用）本服務而造成的損害，不負任何賠償責任。
    </p>
    <p class="mb-1">8、 下載軟體或資料</p>
    <p class="mb-1">
      8.1 HPI對於您使用本服務或經由Lifeeasy
      網站連結之其他網站而下載的軟體或資料之合適性、有效性、正確性、完整性及是否侵害他人權利，不負任何擔保責任。您應於下載前自行斟酌與判斷，以免遭受損失（例如：造成您電腦系統受損、或儲存資料流失等）；HPI對於該等損失不負任何賠償責任。
    </p>
    <p class="mb-1">9、 關於使用及儲存之一般措施</p>
    <p class="mb-4">
      9.1 您同意Lifeeasy
      網站得就本服務訂定一般措施及限制，包含但不限於本服務將保留公告內容或其他上載內容之最長期間、Lifeeasy
      網站伺服器將為您分配的最大磁碟空間，以及一般特定期間內您使用本服務之次數上限（及每次使用時間之上限）。若Lifeeasy
      網站將本服務維持或傳送之任何訊息、通訊和內容刪除或未予儲存，您同意Lifeeasy
      網站毋須承擔任何責任。您亦同意，長時間未使用的帳號，Lifeeasy 網站有權關閉。您也同意，Lifeeasy
      網站有權依其自行之考量，不論通知與否，隨時變更這些一般措施及限制。
    </p>
    <p class="mb-1">10、 資訊或建議</p>
    <p class="mb-1">
      10.1 Lifeeasy 網站對於您使用本服務或經由Lifeeasy
      網站連結之其他網站而取得之資訊或建議（包括但不限於，商務、投資理財、醫療、法律等方面），不擔保其為完全正確無誤。Lifeeasy
      網站對於本服務所提供之資訊或建議有權隨時修改或刪除。您在做出任何相關規劃與決定之前，仍應請教專業人員針對您的情況提出意見，以符合您的個別需求。
    </p>
    <p class="mb-4">
      10.2 Lifeeasy
      網站隨時會與其他公司、廠商等第三人（「內容提供者」）合作，由其提供包括新聞、訊息、電子報等不同內容供Lifeeasy
      網站刊登，Lifeeasy 網站於刊登時均將註明內容提供者。基於尊重內容提供者之智慧財產權，Lifeeasy
      網站對其所提供之內容並不做實質之審查或修改，對該等內容之正確真偽亦不負任何責任。對該等內容之正確真偽，您宜自行判斷之。您若認為某些內容涉及侵權或有所不實，請逕向該內容提供者反應意見。
    </p>

    <p class="mb-1">11、 廣告</p>
    <p class="mb-4">
      11.1 您在 Lifeeasy
      網站上瀏覽到的所有廣告內容、文字與圖片之說明、展示樣品或其他銷售資訊，均由各該廣告商、產品與服務的供應商所設計與提出。您對於廣告之正確性與可信度應自行斟酌與判斷。Lifeeasy
      網站僅接受委託予以刊登，不對前述廣告負擔保責任。
    </p>
    <p class="mb-1">12、 買賣或其他交易行為</p>
    <p class="mb-4">
      12.1 廠商或個人可能透過本服務或經由 Lifeeasy
      網站連結之其他網站提供商品買賣、服務或其他交易行為。您若因此與該等廠商或個人進行交易，各該買賣或其他合約均僅存在您與各該廠商或個人兩造之間。您應要求各該廠商或個人，就其商品、服務或其他交易標的物之品質、內容、運送、保證事項與瑕疵擔保責任等，事先詳細闡釋與說明。您因前述買賣、服務或其他交易行為所產生之爭執，應向各該廠商或個人尋求救濟或解決之道。Lifeeasy
      網站聲明絕不介入您與廠商或個人間的任何買賣、服務或其他交易行為，對於您所獲得的商品、服務或其他交易標的物亦不負任何擔保責任。您若係向HPI經營的購物網站進行前述買賣、服務或其他交易行為，雙方權利義務將另行依據該網站之責任規範約定書內容決定之。
    </p>

    <p class="mb-1">13、 免責聲明</p>
    <p class="mb-1">
      13.1 您明確瞭解並同意Lifeeasy
      網站對本服務及軟體不提供任何明示或默示的擔保，包含但不限於商業適售性、特定目的之適用性及未侵害他人權利。除了法令另有規定外，Lifeeasy、HPI及其子公司、關係企業、經理人、代理人、使用人、受雇人、合夥人、授權人，無須為您直接、間接、附隨、特別、衍生、懲罰性之損害或損失負責，包括但不限於因（a）使用或無法使用本服務；（b）他人未經授權存取或修改您的傳輸或資料；（c）任何第三人就本服務所為之聲明或行為；（d）任何其他與本服務有關者，所生之利潤、商譽、資料之毀損或其他之有形或無形損失。
    </p>
    <p class="mb-1">13.2 Lifeeasy 網站不保證以下事項：</p>
    <p class="mb-1">13.2.1 本服務將符合您的需求；</p>
    <p class="mb-1">13.2.2 本服務不受干擾、及時提供、安全可靠或免於出錯；</p>
    <p class="mb-1">13.2.3 由本服務之使用而取得之結果為正確或可靠；</p>
    <p class="mb-1">
      13.2.4 您經由本服務購買或取得之任何產品、服務、資訊或其他資料將符合您的期望。
    </p>
    <p class="mb-1">13.2.5 任何軟體中之錯誤將被修正。</p>
    <p class="mb-1">
      13.3
      是否經由本服務之使用下載或取得任何資料應由您自行考量且自負風險，因前開任何資料之下載而導致您電腦系統之任何損壞或資料流失，您應負完全責任。
    </p>
    <p class="mb-4">
      13.4 您自Lifeeasy 網站或經由本服務取得之建議和資訊，無論其為書面或口頭，均不構成本服務之保證。
    </p>
    <p class="mb-1">14、 會員行為</p>
    <p class="mb-1">
      14.1
      由會員公開張貼或私下傳送的資訊、資料、文字、軟體、音樂、音訊、照片、圖形、視訊、信息或其他資料（以下簡稱「會員內容」），均由「會員內容」提供者自負責任。Lifeeasy
      網站無法控制經由本服務而張貼之「會員內容」，因此不保証其正確性、完整性或品質。您瞭解使用本服務時，可能會接觸到令人不快、不適當、令人厭惡之「會員內容」。在任何情況下，Lifeeasy
      網站均不為任何「會員內容」負責，包含但不限於任何錯誤或遺漏，以及經由本服務張貼、發送電子郵件或傳送而衍生之任何損失或損害。
    </p>
    <p class="mb-1">
      14.2 您瞭解Lifeeasy 網站並未針對「會員內容」事先加以審查，但Lifeeasy
      網站有權（但無義務）依其自行之考量，拒絕或移除經由本服務提供之任何「會員內容」。在不限制前開規定之前提下，Lifeeasy
      網站及其指定人有權將違反本服務條款和令人厭惡之任何「會員內容」加以移除。您使用任何「會員內容」時，就前開「會員內容」之正確性、完整性或實用性之情形，您同意必須自行加以評估並承擔所有風險。
    </p>
    <p class="mb-1">
      14.3 您瞭解並同意，Lifeeasy
      網站依據法律的要求，或基於以下目的之合理必要範圍內，認定必須將「會員內容」加以保存或揭露予政府機關、司法警察或未成年人之監護人時，得加以保存及揭露：
    </p>
    <p class="mb-1">14.3.1 遵守法令或政府機關之要求律程序；</p>
    <p class="mb-1">14.3.2 執行本服務條款；</p>
    <p class="mb-1">14.3.3 回應任何侵害第三人權利之主張；</p>
    <p class="mb-1">14.3.4 保護Lifeeasy 網站及其使用者及公眾之權利、財產或個人安全。</p>
    <p class="mb-1">
      14.4
      因您經由本服務提供、張貼或傳送「會員內容」、使用本服務、與本服務連線、違反本服務條款、或侵害其他人任何權利所衍生或導致任何第三人為請求或主張時，您同意使Lifeeasy及其子公司、關係企業、經理人、代理人、受僱人、合夥人及授權人免於任何損害。
    </p>
    <p class="mb-4">
      14.5
      您了解本服務及本服務所包含之軟體，可能含有使數位資料得以被保護之安全元件，使用該等資料必須依Lifeeasy或提供該內容予本服務之內容提供者所定的使用規則。您不得試圖破壞或規避任何內含於本服務之使用規則。任何未經合法授權之重製、發行、散布或公開展示本服務所提供之資料之全部或一部，均被嚴格禁止。
    </p>

    <p class="mb-1">15、 智慧財產權的保護</p>
    <p class="mb-4">
      15.1 Lifeeasy
      網站所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由Lifeeasy
      網站或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散佈、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，必須依法取得Lifeeasy
      網站或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對Lifeeasy
      網站或其他權利被侵害之人負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
    </p>
    <p class="mb-1">16、 您對Lifeeasy 網站之授權</p>
    <p class="mb-4">
      16.1
      若您無合法權利得以授權他人使用、修改、重製、公開播送、改作、散佈、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供至Lifeeasy
      網站。任何資料一經您上載、傳送、輸入或提供至Lifeeasy 網站時，即視為您已同意Lifeeasy
      網站可以基於公益或為宣傳、推廣或經營Lifeeasy及本服務之目的，儲存或管理該資料，並無條件使用、修改、重製、公開播送、改作、散佈、發行、公開發表、翻譯該等資料，並得將前述權利轉授權他人，您對此絕無異議。您並應保證Lifeeasy
      網站使用、修改、重製、公開播送、改作、散佈、發行、公開發表、翻譯、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對Lifeeasy
      網站負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
    </p>
    <p class="mb-1">17、 通知</p>
    <p class="mb-4">
      17.1
      如依法或其他相關規定須為通知時，Lifeeasy得以包括但不限於以電子郵件、一般信件、簡訊、多媒體簡訊、文字訊息、張貼於本服務網頁，或其他現在或未來合理之方式通知您，包括本服務條款之變更。但若您違反本服務條款，以未經授權的方式存取本服務，您將不會收到前述通知。當您經由授權的方式存取本服務，而同意本服務條款時，您即同意Lifeeasy所為之任何及所有給您的通知，都視為送達。
    </p>
    <p class="mb-1">18、 拒絕或終止您的使用</p>
    <p class="mb-4">
      18.1 您同意Lifeeasy
      網站得基於其自行之考量，因任何理由，包含但不限於法令規定、一定期間缺乏使用，本服務無法繼續或實質內容變更，或本服務發生無法可服或預期之技術或安全因素問題，或Lifeeasy
      網站認為您已經違反本服務條款的明文規定及精神，終止您的密碼、帳號（或其任何部分）或本服務之使用，並將本服務內任何「會員內容」加以移除並刪除。Lifeeasy
      網站亦得依其自行之考量，於通知或未通知之情形下，隨時終止本服務或其任何部分。您同意依本服務條款任何規定提供之本服務，無需進行事先通知即得終止，您承認並同意，Lifeeasy
      網站得立即關閉或刪除您的帳號及您帳號中所有相關資料及檔案，及停止本服務之使用。此外，您同意若本服務之使用被終止，Lifeeasy
      網站對您或任何第三人均不承擔責任。
    </p>
    <p class="mb-1">19、 準據法與管轄法院</p>
    <p class="mb-4">
      19.1
      本服務條款之解釋與適用，以及與本服務條款有關的爭議，均應依照中華民國法律予以處理，並以台灣台北地方法院為第一審管轄法院。
    </p>
    <p class="mb-1">20、 附則</p>
    <p class="mb-1">
      20.1
      本服務條款構成您與Lifeeasy就您使用本服務之完整合意，取代您先前與Lifeeasy間有關本服務所為之任何約定；但您使用本服務已取得之權利不受影響。Lifeeasy未行使或執行本服務條款任何權利或規定，不構成前開權利或規定之棄權。若任何本服務條款規定，經有管轄權之法院認定有無效之情形，當事人仍同意法院應努力使當事人於前開規定所表達之真意生效，且本服務條款之其他規定不因此而受影響仍應完全有效。
    </p>
    <p class="mb-1">
      20.1
      本服務條款構成您與Lifeeasy就您使用本服務之完整合意，取代您先前與Lifeeasy間有關本服務所為之任何約定；但您使用本服務已取得之權利不受影響。Lifeeasy未行使或執行本服務條款任何權利或規定，不構成前開權利或規定之棄權。若任何本服務條款規定，經有管轄權之法院認定有無效之情形，當事人仍同意法院應努力使當事人於前開規定所表達之真意生效，且本服務條款之其他規定不因此而受影響仍應完全有效。
    </p>
    <p>生效日期：2012年 02月 01 日</p>
  </div>
</template>

<script>
import PageTitle from "@/layout/PageTitle.vue";

export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      breadcrumb: [
        {
          name: "關於仁大",
          path: "/company",
        },
        {
          name: "隱私條款",
          path: "/terms",
        },
      ],
    };
  },
};
</script>
